<script>
export default {
  name: "Not_data"
};
</script>

<template>
  <div class="not_image_warp">
    <el-image
      :src="require('@/assets/common/no_data.png')"
      fit="scale-down"
      lazy
    />
    <div class="msg_content">{{ this.$t("common.noContent") }}</div>
  </div>
</template>

<style scoped lang="scss">
.not_image_warp {
  height: 300px;
  text-align: center;
  position: relative;
  .el-image {
    height: 300px;
  }
  .msg_content {
    color: #a8a7a7;
    font-size: 1.2rem;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 82%;
  }
}
</style>
