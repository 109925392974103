<script>
import LearnMore from "@/views/components/LearnMore.vue";

export default {
  name: "ArtImageList",
  props: {
    images: Array
  },
  components: { LearnMore }
};
</script>

<template>
  <div class="image_list_warp">
    <div v-for="item in images" :key="item.key" class="image_item">
      <el-image :src="item.img" fit="contain" lazy />
      <!--                标题-->
      <div class="art_params_comm">
        <span class="title">{{ item.title }}</span>
        <div class="info">材质：{{ item.material }}</div>
        <div class="info">尺寸：{{ item.dimension }}</div>
        <div class="pice">
          <div
            v-if="item.price && item.price !== '0' && item.price !== 0"
            class="yes"
          >
            <span style="font-size: 0.7rem;">￥</span>{{ item.price }}
          </div>
          <div v-else class="no">
            <img
              :src="require('@/assets/common/price.png')"
              style="width: 14px;margin-right: 6px;margin-bottom: 4px;"
            />暂无报价
          </div>
          <div class="desc">详情</div>
        </div>
      </div>
    </div>
    <LearnMore />
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";

.image_list_warp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .image_item {
    margin-top: 48px;
    text-align: center;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    width: 30%;
    .title {
      margin-top: 10px;
      .el-image {
        height: 20px;
      }
    }
    span {
      font-size: 1rem;
    }
    .title_warp {
      margin-left: 20px;
    }
    .art_params_comm {
      line-height: 30px;
      text-align: left;
      margin: 20px;
    }
    .art_params_comm .title {
      color: $theme-red;
      font-size: 1.2rem;
    }
    .art_params_comm .title {
      color: $theme-red;
      font-size: 1.2rem;
    }
    .art_params_comm .info {
      font-size: 0.8rem;
    }
    .art_params_comm .pice {
      display: flex;
      justify-content: space-between;
      color: $theme-red;
      .no {
        color: rgba(0, 0, 0, 0.85);
      }
      .desc {
        cursor: pointer;
        border: 1px solid $theme-red;
        width: 55px;
        text-align: center;
        height: 28px;
        line-height: 24px;
        border-radius: 4px;
      }
      .desc:hover {
        background-color: $theme-red;
        color: #ffffff;
        transform: scale(1.06);
        transition: all 1s;
      }
    }
    .el-image {
      width: 100%;
      //margin-bottom: 10px;
    }
  }
  .more_warp {
    margin-top: 56px;
  }
}
</style>
