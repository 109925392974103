<script>
export default {
  name: "LearnMore",
  methods: {
    seeMoreFun() {
      if (this.$parent.seeMoreFun) {
        this.$parent.seeMoreFun();
      }
    }
  }
};
</script>

<template>
  <div class="more_warp" @click="seeMoreFun">
    <div class="learn_more_warp">
      <el-image
        :src="require('@/assets/home/news_but.png')"
        fit="scale-down"
        lazy
      />
      <!--        查看更多-->
      <div class="des">{{ this.$t("home.newsCenter.viewMore") }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./index.scss";
.more_warp {
  width: 100%;
  margin-top: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
}
.learn_more_warp {
  text-align: center;
}
.learn_more_warp .el-image {
  width: 10px;
}
.learn_more_warp .des {
  color: $theme-red;
  font-size: 0.8rem;
  margin-top: 6px;
  border: 1px solid $theme-red;
  border-radius: 4px;
  padding: 4px 8px;
}
.el-image:hover {
  transform: scale(1.2);
  transition: all 1s;
}
.des:hover {
  transform: scale(1.2);
  transition: all 1s;
  background-color: $theme-red;
  color: #ffffff;
}
</style>
