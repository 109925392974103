<script>
import Banner from "@/views/components/Banner.vue";
import Breadcrumb from "@/views/components/Breadcrumb.vue";
import LeftMenu from "@/views/components/LeftMenu.vue";
import { tabsOption } from "@/views/artwork/moke";
import notData from "@/views/components/Not_data.vue";
import ArtImageList from "@/views/components/ArtImageList.vue";

export default {
  name: "index",
  components: { ArtImageList, notData, LeftMenu, Breadcrumb, Banner },
  data() {
    return {
      tabsOption: tabsOption,
      currentTab: {}, // 当前选中的标签页数据
      breadcrumbOption: [], // 面包屑
      childSelect: {}, // 页面选中的节点
      showData: {} // 选中要显示得数据
    };
  },
  mounted() {
    this.currentTab = this.tabsOption[0];
    this.childSelect = this.tabsOption[0];
    this.checkTab(this.currentTab.key);
  },
  methods: {
    /**
     * 切换一级目录
     * @param key
     */
    checkTab(key) {
      this.childSelect = {};
      this.showData = {};
      // 初始化三级目录
      const tabsOption = this.tabsOption;
      tabsOption.map(item => {
        if (item.key === key) {
          this.currentTab = item;
          // 添加面包屑
          const currentOption = [
            {
              key: "artwork",
              toPath: "/artwork"
            }
          ];
          currentOption.push({
            key: item.key,
            title: item.title,
            toPath: "/artwork"
          });
          this.breadcrumbOption = currentOption;
          if (
            item.menuChild &&
            item.menuChild[0] &&
            item.menuChild[0].menuChild
          ) {
            const menuChildObj = item.menuChild[0];
            menuChildObj.isCheck = true;
            this.checkChildSelect(menuChildObj, 2);
          } else {
            this.checkChildSelect(item, 2);
          }
        }
      });
    },
    /**
     * 切换二级，三级目录保存数据
     * @param item
     * @param menuCount 面包屑的位数
     */
    checkChildSelect(item, menuCount) {
      // 添加面包屑 （暂时关闭）start
      const currentOption = this.breadcrumbOption;
      const isFlog = currentOption.some(o => o.key === item.key);
      if (!isFlog) {
        const newOption = {
          key: item.key,
          title: item.title,
          toPath: "/artist?sel=" + item.key
        };
        currentOption.splice(menuCount, menuCount + 1, newOption);
        this.breadcrumbOption = currentOption;
      }
      // 添加面包屑 end
      // 没有三级目录，三级目录不需要选中当前的菜单节点
      if (item.menuChild && item.menuChild[0]) {
        this.childSelect = item.menuChild[0];
        this.showData = item.menuChild[0];
      } else {
        this.childSelect = item;
        this.showData = item;
      }
    }
  }
};
</script>

<template>
  <div>
    <Banner
      :bannerSrc="require('@/assets/banner/artwork.jpg')"
      :tabs="tabsOption"
      :selTab="currentTab.key"
    />
    <div class="padding-comm-warp _content_warp work_warp">
      <div class="left">
        <LeftMenu :currentTab="currentTab" :childSelect="childSelect" />
      </div>
      <div class="right">
        <Breadcrumb :breadcrumbList="breadcrumbOption" />
        <div
          class="content"
          v-if="showData.itemOption && showData.itemOption.length !== 0"
        >
          <ArtImageList :images="showData.itemOption" />
        </div>
        <div v-else>
          <notData />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";

.work_warp {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
</style>
